import { AuthUser } from '@/features/auth';

const storagePrefix = 'rooka_web_app_';

const storage = {
  getToken: () => {
    const token = window.localStorage.getItem(`${storagePrefix}token`);
    return !!token && token !== 'undefined' ? JSON.parse(token) : null;
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },

  setRefreshToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}refresh_token`, JSON.stringify(token));
  },
  getRefreshToken: () => {
    const token = window.localStorage.getItem(`${storagePrefix}refresh_token`);
    return !!token && token !== 'undefined' ? JSON.parse(token) : null;
  },
  clearRefreshToken: () => {
    window.localStorage.removeItem(`${storagePrefix}refresh_token`);
  },

  setTokenId: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token_id`, JSON.stringify(token));
  },
  getTokenId: () => {
    const token = window.localStorage.getItem(`${storagePrefix}token_id`);
    return !!token && token !== 'undefined' ? JSON.parse(token) : null;
  },
  clearTokenId: () => {
    window.localStorage.removeItem(`${storagePrefix}token_id`);
  },

  getUser: () => {
    const user = window.localStorage.getItem(`${storagePrefix}user`);
    return !!user && user !== 'undefined' ? JSON.parse(user) : null;
  },
  setUser: (user: AuthUser) => {
    window.localStorage.setItem(`${storagePrefix}user`, JSON.stringify(user));
  },
  clearUser: () => {
    window.localStorage.removeItem(`${storagePrefix}user`);
  },
};

export default storage;
