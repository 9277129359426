import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useDarkMode } from '@/hooks/useDarkMode';
import { useAuth } from '@/lib/auth';

const schema = z
  .object({
    email: z.string().min(1, 'Required'),
    password: z.string().min(1, 'Required'),
    confirmPassword: z.string().min(1, 'Required'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  });

type RegisterValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

type RegisterFormProps = {
  onSuccess: () => void;
};

export const RegisterForm = ({ onSuccess }: RegisterFormProps) => {
  useDarkMode();
  const { register, isRegistering } = useAuth();
  const { t } = useTranslation('register');

  return (
    <div>
      <Form<RegisterValues, typeof schema>
        onSubmit={async (values) => {
          await register(values);
          onSuccess();
        }}
        schema={schema}
        options={{
          shouldUnregister: true,
        }}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label={t('email')}
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label={t('password')}
              error={formState.errors['password']}
              registration={register('password')}
            />
            <InputField
              type="password"
              label={t('confirmPassword')}
              error={formState.errors['confirmPassword']}
              registration={register('confirmPassword')}
            />

            <div>
              <Button
                isLoading={isRegistering}
                type="submit"
                className="w-full"
                disabled={isRegistering}
              >
                {t('register')}
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-end">
        <div className="text-sm">
          <Link to="../login" className="font-medium text-blue-600 hover:text-blue-500">
            {t('logIn')}
          </Link>
        </div>
      </div>
    </div>
  );
};
