import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useDarkMode } from '@/hooks/useDarkMode';
import { useAuth } from '@/lib/auth';

const schema = z.object({
  email: z.string().min(1, 'Required'),
  password: z.string().min(1, 'Required'),
});

type LoginValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  useDarkMode();
  const { login, isLoggingIn } = useAuth();
  const { t } = useTranslation('login');

  return (
    <div>
      <Form<LoginValues, typeof schema>
        onSubmit={async (values) => {
          await login(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label={t('email')}
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label={t('password')}
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <Button
                isLoading={isLoggingIn}
                type="submit"
                className="w-full"
                disabled={isLoggingIn}
              >
                {t('logIn')}
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-between">
        <div className="text-sm">
          <Link to="../forgot-password" className="font-medium text-blue-600 hover:text-blue-500">
            {t('forgotPassword')}
          </Link>
        </div>
        <div className="text-sm">
          <Link to="../register" className="font-medium text-blue-600 hover:text-blue-500">
            {t('register')}
          </Link>
        </div>
      </div>
    </div>
  );
};
