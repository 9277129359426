import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ForgotPasswordForm } from '../components/ForgotPasswordForm';
import { Layout } from '../components/Layout';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('forgotPassword');

  return (
    <Layout title={t('header')}>
      <ForgotPasswordForm onSuccess={() => navigate('../reset-password')} />
    </Layout>
  );
};
