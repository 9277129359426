import { CreditCardIcon } from '@heroicons/react/outline';

export const SubscriptioninfoCard = () => {
  return (
    <div className="relative flex-shrink-0 max-w-xs overflow-hidden bg-blue-500 rounded-lg shadow-lg sm:mb-0">
      <svg className="absolute bottom-0 left-0 mb-8" viewBox="0 0 375 283" fill="none">
        <rect
          x="159.52"
          y="175"
          width="152"
          height="152"
          rx="8"
          transform="rotate(-45 159.52 175)"
          fill="#6da3fb"
        ></rect>
        <rect
          y="107.48"
          width="152"
          height="152"
          rx="8"
          transform="rotate(-45 0 107.48)"
          fill="#6da3fb"
        ></rect>
      </svg>
      <div className="relative flex items-center justify-start px-10 pt-10">
        <div className="absolute bottom-0 left-0 block w-48 h-48 ml-3 -mb-24"></div>
        <CreditCardIcon width={50} className="text-white" />
      </div>
      <div className="relative px-6 pb-6 mt-6 text-white">
        <span className="block -mb-1 opacity-75">Free tier</span>
        <div className="flex justify-between">
          <span className="block text-Nl font-semibold">Upgrade your plan</span>
          <span className="flex items-center px-3 py-2 text-xs font-bold leading-none text-blue-500 bg-white rounded-full">
            $15.00
          </span>
        </div>
      </div>
    </div>
  );
};
