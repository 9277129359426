import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { ResetPasswordForm } from '../components/ResetPasswordForm';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('resetPassword');

  return (
    <Layout title={t('header')}>
      <ResetPasswordForm onSuccess={() => navigate('../login')} />
    </Layout>
  );
};
