import { ContentLayout } from '@/components/Layout';
import { useAuth } from '@/lib/auth';

export const Dashboard = () => {
  const { user } = useAuth();
  return (
    <ContentLayout title="Dashboard">
      <h1 className="text-xl mt-2">
        Welcome <b>{user?.email}</b>
      </h1>
    </ContentLayout>
  );
};
