import Axios, { AxiosRequestConfig } from 'axios';
import i18next from 'i18next';

import { AUTH_API_URL } from '@/config';
import { RefreshTokenResponseDTO } from '@/features/auth';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';

// TODO (Patryk) - handle refresh token

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}

export const authAxios = Axios.create({
  baseURL: AUTH_API_URL,
});

const refreshTheToken = async (data): Promise<RefreshTokenResponseDTO> =>
  await authAxios
    .post<RefreshTokenResponseDTO>('/auth_api/refresh_token', data)
    .then((response) => {
      const { accessToken, refreshToken, idToken } = response.data.authenticationResult;
      storage.setToken(accessToken);
      storage.setRefreshToken(refreshToken);
      storage.setTokenId(idToken);
      return response.data;
    });

authAxios.interceptors.request.use(authRequestInterceptor);
authAxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && storage.getRefreshToken()) {
      const refreshToken = storage.getRefreshToken();
      const tokenId = storage.getTokenId();

      const data = JSON.stringify({
        refresh_token: refreshToken,
        id_token: tokenId,
      });

      const access_token = await refreshTheToken(data);
      // Re-run the original request that was intercepted
      originalRequest.headers.Authorization = `Bearer ${access_token.authenticationResult.accessToken}`;
      return authAxios(originalRequest);
    }

    const message = error.response?.data?.message || error.message;
    useNotificationStore.getState().addNotification({
      type: 'error',
      title: i18next.t('common.error'),
      message,
    });

    return Promise.reject(error);
  }
);
