import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useDarkMode } from '@/hooks/useDarkMode';
import { useAuth } from '@/lib/auth';

const schema = z.object({
  email: z.string().min(1, 'Required'),
});

type ForgotPasswordValues = {
  email: string;
};

type ForgotPasswordFormProps = {
  onSuccess: () => void;
};

export const ForgotPasswordForm = ({ onSuccess }: ForgotPasswordFormProps) => {
  useDarkMode();
  const { isForgotingPassword, forgotPassword } = useAuth();
  const { t } = useTranslation('forgotPassword');

  return (
    <div>
      <Form<ForgotPasswordValues, typeof schema>
        onSubmit={async (values) => {
          await forgotPassword(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label={t('email')}
              error={formState.errors['email']}
              registration={register('email')}
            />
            <div>
              <Button
                isLoading={isForgotingPassword}
                type="submit"
                className="w-full"
                disabled={isForgotingPassword}
              >
                {t('resetPassword')}
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-end">
        <div className="text-sm">
          <Link to="../login" className="font-medium text-blue-600 hover:text-blue-500">
            {t('logIn')}
          </Link>
        </div>
      </div>
    </div>
  );
};
