import { authAxios } from '@/lib/axios';

import { DownloadResponse } from '../types';

export const startDownload = async (): Promise<void> => {
  try {
    const response: DownloadResponse = await authAxios.get('/auth_api/download');

    if (response.installer_link) {
      const anchor = document.createElement('a');
      anchor.href = response.installer_link;
      anchor.setAttribute('download', 'installer');
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  } catch (err) {
    // Can be ignored or logged
    console.error('Error initiating download', err);
  }
};
