import { useRef } from 'react';

let uniqueIdCounter = 0;

export const useUniqueId = (prefix = 'unique-id') => {
  const idRef = useRef(null);

  if (idRef.current === null) {
    idRef.current = `${prefix}-${uniqueIdCounter++}`;
  }

  return idRef.current;
};
