import { useEffect, useState, Dispatch, SetStateAction } from 'react';

// Correctly typed return value for the useDarkMode hook
export const useDarkMode = (): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [darkMode, setDarkMode] = useState<boolean>(() => {
    const theme = localStorage.getItem('theme');
    return (
      theme === 'dark' ||
      (theme !== 'light' && window.matchMedia('(prefers-color-scheme: dark)').matches)
    );
  });

  useEffect(() => {
    const className = 'dark';
    const element = document.documentElement;

    if (darkMode) {
      element.classList.add(className);
      localStorage.setItem('theme', 'dark');
    } else {
      element.classList.remove(className);
      localStorage.setItem('theme', 'light');
    }
  }, [darkMode]);

  return [darkMode, setDarkMode];
};
