import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { RegisterForm } from '../components/RegisterForm';

export const Register = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('register');

  return (
    <Layout title={t('header')}>
      <RegisterForm onSuccess={() => navigate('../login')} />
    </Layout>
  );
};
