import { authAxios } from '@/lib/axios';

import { IntercomUserResponse } from '../types';

export const getIntercomUser = async (): Promise<IntercomUserResponse> => {
  try {
    return await authAxios.get('/auth_api/intercom/user');
  } catch (err) {
    console.error('Error getting intercom user data', err);
    return null;
  }
};
