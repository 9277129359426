import { useEffect, useState } from 'react';

import { getIntercomUser } from '../api/get-intercom-user';

export const useIntercom = () => {
  const [intercomLoaded, setIntercomLoaded] = useState(false);

  useEffect(() => {
    // Define an async function inside the useEffect
    const loadIntercomData = async () => {
      if (typeof window !== 'undefined' && !intercomLoaded) {
        const intercomData = await getIntercomUser();
        if (!intercomData) {
          return;
        }

        const appId = intercomData?.AppId ?? 'fkcuqcco';

        const anyWindow: any = window;

        anyWindow.Intercom =
          anyWindow.Intercom ||
          function (...args) {
            anyWindow.Intercom.q.push(args);
          };
        anyWindow.Intercom.q = anyWindow.Intercom.q || [];

        // Insert the Intercom script into the <head>
        const ic = document.createElement('script');
        ic.type = 'text/javascript';
        ic.async = true;
        ic.src = `https://widget.intercom.io/widget/${appId}`;
        ic.onload = () => {
          anyWindow.Intercom('boot', {
            app_id: appId,
            user_id: intercomData.Username,
            user_hash: intercomData.HashedUsername,
          });
          setIntercomLoaded(true);
        };

        const head = document.head || document.getElementsByTagName('head')[0];
        head.appendChild(ic);
      }
    };

    // Call the async function
    loadIntercomData();
  }, [intercomLoaded]); // Add intercomLoaded to the dependency array to prevent re-running if it's set
};
