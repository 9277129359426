import { Spinner } from '@/components/Elements';
import {
  AuthUser,
  ForgotPasswordRequestDTO,
  LoginRequestDTO,
  LoginResponseDTO,
  RegisterRequestDTO,
  ResetPasswordRequestDTO,
  loginWithEmailAndPassword,
  registerWithEmailAndPassword,
  forgotPassword,
  resetPassword,
} from '@/features/auth';
import { initReactQueryAuth } from '@/stores/auth';
import storage from '@/utils/storage';

async function handleUserResponse(data: LoginResponseDTO): Promise<AuthUser> {
  const { accessToken, refreshToken, idToken } = data.authenticationResult;
  storage.setToken(accessToken);
  storage.setRefreshToken(refreshToken);
  storage.setTokenId(idToken);
  storage.setUser(data.user);

  return Promise.resolve(data.user);
}

async function loadUser() {
  const user = storage.getUser();
  if (user) {
    return user;
  }
  return null;
}

async function loginFn(data: LoginRequestDTO): Promise<AuthUser> {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn(data: RegisterRequestDTO): Promise<any> {
  const response = await registerWithEmailAndPassword(data);
  return response;
}

async function logoutFn() {
  storage.clearToken();
  storage.clearUser();
  storage.clearRefreshToken();
  storage.clearTokenId();
  window.location.assign(window.location.origin as unknown as string);
}

async function resetPasswordFn(data: ResetPasswordRequestDTO) {
  const response = await resetPassword(data);
  return response;
}

async function forgotPasswordFn(data: ForgotPasswordRequestDTO) {
  const response = await forgotPassword(data);
  return response;
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  resetPasswordFn,
  forgotPasswordFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="xl" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  AuthUser | null,
  unknown,
  LoginRequestDTO,
  RegisterRequestDTO
>(authConfig);
