import camelcaseKeys from 'camelcase-keys';

import { authAxios } from '@/lib/axios';

import { LoginRequestDTO, LoginResponseDTO } from '../types';

export const loginWithEmailAndPassword = async (
  data: LoginRequestDTO
): Promise<LoginResponseDTO> => {
  const response = await authAxios.post('/auth_api/login', data);
  console.log(response);

  const result: LoginResponseDTO = {
    ...(response as any),
    user: {
      email: data.email,
    },
  };

  return camelcaseKeys(result, { deep: true });
};
