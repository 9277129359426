import { DownloadIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Elements';

import { startDownload } from '../api/downloadLink';

export const DownloadButton = () => {
  const { t } = useTranslation('navigation');

  const handleDownload = async () => {
    await startDownload();
  };

  return (
    <div>
      <Button onClick={handleDownload} startIcon={<DownloadIcon />} className="w-full">
        {t('downloadButton')}
      </Button>
    </div>
  );
};
